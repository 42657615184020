<!--
 * @Author: wyq
 * @Date: 2021-09-01 14:45:11
 * @LastEditTime: 2021-10-28 17:52:27
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\components\knowledgeGraph\editRootDialog.vue
-->
<template>
	<el-dialog
		:title="value ? '编辑媒资图谱' : '添加媒资图谱'"
		:visible.sync="dialogVisible"
		width="400px"
		:close-on-click-modal="false"
	>
		<el-form ref="form" :model="form" :rules="rules" label-position="top" size="mini">
			<el-form-item prop="name">
				<el-input placeholder="请输入名称" v-model="form.name" size="mini"></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="close">取 消</el-button>
			<el-button type="primary" @click="submit">确 定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import mixin from '@/utils/dialogMixin'
export default {
	mixins: [mixin],
	props: {
		value: Object,
		type: String,
	},
	data() {
		return {
			form: {
				name: '',
			},
			rules: {
				name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
			},
		}
	},
	watch: {
		visible: {
			handler(val) {
				if (val) {
          console.log(this.type,this.value)
					if (this.type == 'edit') {
						this.form.name = this.value.label
					} else {
						this.form.name = ''
					}
				} else {
					this.form.name = ''
					this.$refs['form'].resetFields()
				}
			},
			immediate: true,
		},
	},
	methods: {
		submit() {
			this.form.name = this.form.name.trim()
			this.$refs['form'].validate((valid) => {
				if (valid) {
					if (this.type == 'edit') {
						console.log('编辑成功')
						let params = {
							id: this.value.id,
							label: this.form.name,
						}
						this.$emit('updateLabel', params)
					} else {
						console.log('添加成功')
						let params = {
							label: this.form.name,
							parentId: this.value.id,
						}
						this.$emit('createLabel', params)
					}
					this.dialogVisible = false
				}
			})
		},
	},
}
</script>
<style lang="scss" scoped></style>
