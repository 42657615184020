<!--
 * @Author: wyq
 * @Date: 2021-08-30 14:42:03
 * @LastEditTime: 2021-10-27 10:07:23
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\views\knowledgeGraph.vue
-->
<template>
	<page-container class="page-knowledge-graph" asideTitle="媒资图谱">
		<el-button slot="asideTitle" type="text" @click="showDialog({ id: 0 }, 'add')">
			添加
		</el-button>
		<ul slot="aside" class="aside-bar">
			<li
				v-for="item in asideData"
				:key="item.id"
				:class="{ active: item.id == currentId }"
				@click="changeCurrentId(item.id)"
			>
				{{ item.label }}
				<el-dropdown @command="handleCommand($event, item)">
					<i class="el-icon-more"></i>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="edit">编辑</el-dropdown-item>
						<el-dropdown-item command="delete">删除</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</li>
		</ul>
		<content-container title="媒资图谱配置">
			<div class="item-box">
				<div class="label">
					<span>媒资图谱</span>
					<el-button
						type="primary"
						plain
						size="mini"
						@click="showDialog({ id: currentId }, 'add')"
					>
						添加父级
					</el-button>
				</div>
				<el-input
					placeholder="输入关键字进行过滤"
					v-model="filterText"
					size="mini"
				></el-input>
				<el-scrollbar style="height: 500px">
					<el-tree
						ref="tree"
						:data="treeData"
						node-key="id"
						default-expand-all
						:expand-on-click-node="false"
						:filter-node-method="filterNode"
						@node-click="treeNodeClick"
					>
						<span class="custom-tree-node" slot-scope="{ node, data }">
							<span>{{ node.label }}</span>
							<span v-show="currentTree.id == data.id">
								<el-button
									type="warning"
									size="mini"
									plain
									@click="showDialog(data, 'edit')"
								>
									编辑
								</el-button>
								<el-button
									type="primary"
									size="mini"
									plain
									@click="showDialog(data, 'add')"
								>
									添加子集
								</el-button>
								<el-button
									type="danger"
									size="mini"
									plain
									@click="deleteLabel(data.id)"
								>
									删除
								</el-button>
							</span>
						</span>
					</el-tree>
				</el-scrollbar>
			</div>
		</content-container>
		<editVideoDialog
			:visible.sync="dialogVisible"
			:value="dialogValue"
			:type="dialogType"
			@updateLabel="updateLabel"
			@createLabel="createLabel"
		></editVideoDialog>
	</page-container>
</template>
<script>
import editVideoDialog from '../components/knowledgeGraph/editVideoDialog'
export default {
	components: {
		editVideoDialog,
	},
	data() {
		return {
			asideData: [],
			currentId: null,
			filterText: '',
			currentTree: {},
			dialogName: '',
			dialogValue: null,
			dialogVisible: false,
			dialogType: '',
		}
	},
	computed: {
		treeData() {
			const currentAsideTree = this.asideData.find((i) => {
				return i.id == this.currentId
			})
			return currentAsideTree ? currentAsideTree.children : []
		},
	},
	watch: {
		// currentId(val) {
		// 	if (val) {
		// 		this.getTree()
		// 	}
		// },
		filterText(val) {
			this.$refs.tree.filter(val)
		},
	},
	methods: {
		getList() {
			this.$service.videoGraphList().then((res) => {
				this.asideData = res.graph || []
				this.currentId = res.graph ? res.graph[0].id : null
				this.currentTree = {}
			})
		},
		updateLabel(params) {
			this.$service.updateVideoLabel(params).then(() => {
				this.refreshTree()
				this.$message({
					type: 'success',
					message: '编辑成功!',
				})
			})
		},
		createLabel(params) {
			this.$service.createVideoLabel(params).then(() => {
				this.refreshTree()
				this.$message({
					type: 'success',
					message: '添加成功!',
				})
			})
		},
		changeCurrentId(id) {
			this.currentId = id
			this.getTree()
		},
		filterNode(value, data) {
			if (!value) return true
			return data.label.indexOf(value) !== -1
		},
		treeNodeClick(obj) {
			this.currentTree = obj
		},
		showDialog(item = null, type = '') {
			this.dialogValue = item
			this.dialogVisible = true
			this.dialogType = type
		},
		handleCommand(command, item) {
			if (command == 'edit') {
				this.showDialog(item, 'edit')
			} else if (command == 'delete') {
				this.deleteLabel(item.id)
			}
		},
		refreshTree() {
			this.getList()
		},
		deleteLabel(id) {
			this.$confirm('此操作将永久删除该图谱, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				confirmButtonClass: 'el-button--danger',
				type: 'error',
			}).then(() => {
				let params = {
					id: id,
				}
				this.$service.deleteVideoLabel(params).then(() => {
					this.refreshTree()
					this.$message({
						type: 'success',
						message: '删除成功!',
					})
				})
			})
		},
	},
	mounted() {
		this.getList()
	},
}
</script>
<style lang="scss" scoped>
.page-knowledge-graph {
	.aside-bar {
		padding: 0;
		li {
			position: relative;
			display: block;
			height: 42px;
			padding: 0 36px;
			line-height: 42px;
			cursor: pointer;
			color: #999;
			font-size: 14px;
			&.active {
				background-color: #4e9c95;
				color: #fff;
			}
			.el-dropdown {
				position: absolute;
				right: 32px;
				color: inherit;
			}
		}
	}
	.item-box {
		font-size: 14px;
		color: #999999;
		white-space: pre-wrap;
		&:last-of-type {
			margin-bottom: 20px;
		}
		.label {
			margin: 20px 0;
			color: #999999;
			span {
				margin-right: 22px;
				color: #333333;
			}
		}
		.el-tag {
			border-radius: 7px;
			cursor: pointer;
			& + .el-tag {
				margin-left: 16px;
			}
		}
		.el-scrollbar {
			margin: 20px 0;
			background-color: #f4f6f8;
		}
		.el-tree {
			padding: 20px 0;
			background-color: #f4f6f8;
		}
		::v-deep .el-tree-node__content {
			height: 36px;
			line-height: 36px;
		}
		::v-deep .custom-tree-node {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 14px;
			padding-right: 8px;
			.el-button--mini {
				margin-right: 10px;
			}
		}
	}
	.desc-imgs img {
		margin-top: 10px;
		margin-right: 10px;
		max-width: 100px;
		max-height: 200px;
	}
}
</style>
